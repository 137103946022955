import React from "react";
import LayoutForOrder from "../components/LayoutForOrder";
import List from "../components/parts/userGuide/List";
import Table from "../components/parts/userGuide/Table";
import AnnotationText from "../components/section/AnnotationText";
import { StyledOrderH2, StyledOrderH3, StyledOrderP } from "../styled/SText";

const UserGuide = ({ location }) => {
  const toclist = [
    {
      title: "ご利用案内",
      url: "#userGuide",
      items: [
        { title: "クール冷凍便送料", url: "#postage" },
        { title: "時間指定", url: "#appointmentOfTime" },
      ],
    },
  ];
  const appointmentOfTimes = [
    { text: "午前中(8時~12時)" },
    { text: "14時~16時" },
    { text: "16時~18時" },
    { text: "18時~20時" },
    { text: "19時~21時" },
  ];
  return (
    <LayoutForOrder
      mainTitle="ご利用案内"
      subTitle="user guide"
      panTitle="ご利用案内"
      panLocation={location}
      tocData={toclist}
    >
      <StyledOrderH2 id={toclist[0].url.slice(1)}>ご利用案内</StyledOrderH2>
      <StyledOrderH3 id={toclist[0].items[0].url.slice(1)}>
        クール冷凍便送料
      </StyledOrderH3>
      <StyledOrderP>発送はヤマト運輸のクール冷凍便になります。</StyledOrderP>
      <Table />
      <AnnotationText
        num="1"
        text="お菓子箱の大きさにや包装により、段ボールサイズが80になる場合がございます。"
      />

      <StyledOrderH3 id={toclist[0].items[1].url.slice(1)}>
        時間指定
      </StyledOrderH3>
      <StyledOrderP>
        時間指定がある場合は下記の中からお選びください。
      </StyledOrderP>
      <List data={appointmentOfTimes} />
    </LayoutForOrder>
  );
};

export default UserGuide;
