import React from "react";
import styled from "styled-components";

const AnnotationText = ({ num, text }) => {
  return (
    <StyledAnnotationDiv>
      <span className="annotation">※{num}</span>
      <p className="annotation__text">{text}</p>
    </StyledAnnotationDiv>
  );
};
const StyledAnnotationDiv = styled.div`
  display: flex;
  align-items: center;
  .annotation {
    font-size: 1.2rem;
    color: var(--red-color);
    &__text {
      font-size: 1.5rem;
      color: var(--black-color);
      line-height: 2rem;
    }
  }
`;

export default AnnotationText;
