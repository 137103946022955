import styled from "styled-components";

const tableColor = "var(--lightGray-color)";
const STable = styled.table`
  border-radius: 10px;
  width: 100%;
  th,
  td {
    text-align: center;
    font-size: 1.5rem;
    padding: 1.5rem 1.5rem;
  }
  th {
    border-right: 1px solid ${tableColor};

    width: 20%;
  }
  td {
    &:not(:last-child) {
      border-right: 1px solid ${tableColor};
    }
    width: 40%;
  }
  thead {
    background-color: var(--white-color);
    border-bottom: 1px solid ${tableColor};
    th {
      font-size: 1.8rem;
      font-weight: bold;
    }
  }
  tbody {
    tr {
      &:nth-child(odd) {
        background-color: var(--lightGray2-color);
      }
    }
  }
`;
export default STable;
