import React from "react";
import styled from "styled-components";
import STable from "../../../styled/STable";

const Table = () => {
  return (
    <StyledDiv>
      <STable>
        <table>
          <tbody>
            <tr>
              <th>サイズ</th>
              <td>60</td>
              <td>80</td>
            </tr>
            <tr>
              <th>個数</th>
              <td>
                <span className="annotation">※1</span>約16個まで
              </td>
              <td>約17~40個</td>
            </tr>
            <tr>
              <th>九州、北海道</th>
              <td>1480円</td>
              <td>1700円</td>
            </tr>
            <tr>
              <th>四国、中国</th>
              <td>1360円</td>
              <td>1480円</td>
            </tr>
            <tr>
              <th>関西、青森、秋田、岩手</th>
              <td>1150円</td>
              <td>1370円</td>
            </tr>
            <tr>
              <th>その他</th>
              <td>1040円</td>
              <td>1260円</td>
            </tr>
          </tbody>
        </table>
      </STable>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  border-radius: 3px;
  overflow: hidden;
  /* border: 1px solid var(--lightGray-color); */
  /* box-shadow: 0 0px 5px 0px var(--lightGray-color); */
  margin-bottom: 2rem;
`;
export default Table;
